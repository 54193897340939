exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catalogo-js": () => import("./../../../src/pages/catalogo.js" /* webpackChunkName: "component---src-pages-catalogo-js" */),
  "component---src-pages-contactanos-js": () => import("./../../../src/pages/contactanos.js" /* webpackChunkName: "component---src-pages-contactanos-js" */),
  "component---src-pages-en-catalogo-js": () => import("./../../../src/pages/en/catalogo.js" /* webpackChunkName: "component---src-pages-en-catalogo-js" */),
  "component---src-pages-en-contactanos-js": () => import("./../../../src/pages/en/contactanos.js" /* webpackChunkName: "component---src-pages-en-contactanos-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-nuestras-marcas-js": () => import("./../../../src/pages/en/nuestras_marcas.js" /* webpackChunkName: "component---src-pages-en-nuestras-marcas-js" */),
  "component---src-pages-en-servicios-js": () => import("./../../../src/pages/en/servicios.js" /* webpackChunkName: "component---src-pages-en-servicios-js" */),
  "component---src-pages-en-sobre-nosotros-js": () => import("./../../../src/pages/en/sobre_nosotros.js" /* webpackChunkName: "component---src-pages-en-sobre-nosotros-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nuestras-marcas-js": () => import("./../../../src/pages/nuestras_marcas.js" /* webpackChunkName: "component---src-pages-nuestras-marcas-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-sobre-nosotros-js": () => import("./../../../src/pages/sobre_nosotros.js" /* webpackChunkName: "component---src-pages-sobre-nosotros-js" */)
}

